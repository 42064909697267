@import 'src/styles/mixins';
@import 'src/styles/variables';

.loading {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  opacity: 1;
  background: $white;
  z-index: 99999;
}

.out {
  animation-name: hide;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.logoWrapper {
  width: 360px;
  padding: 0 16px;
  @include absoluteCenter();

  top: 40%;
}

.hidden {
  display: none !important;
  visibility: none !important;
}

@keyframes hide {
  0% {
    opacity: 1;
  }

  99% {
    opacity: 0;
  }

  100% {
    visibility: hidden;
  }
}
