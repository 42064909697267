///////////////////
//    Colors     //
///////////////////

///////////
// BLUES //
///////////

// Darker
$navy: #205785;
$blue-700: #0e69ba;

$blue-500: #118fff;

// Lighter
$blue-400: #51adff;
$blue-300: #66b7ff;
$blue-200: #bde0ff;
$blue-100: #e9f4ff;

/////////////
// PURPLES //
/////////////

// Darker
$dark-purple: #414379;

// Lighter
$light-purple: #4143791f;

////////////
// GREENS //
////////////

// Darker
$mineral-green-700: #33534c;
$mineral-green-600: #1c6b57;
$mineral-green-500: #007e5e;

// Lighter
$mineral-green-400: #559690;
$mineral-green-300: #73a8a3;
$mineral-green-200: #9bc1be;
$mineral-green-100: #c3dad8;
$mineral-green-50: #f0f7f6;

// Darker
$spruce-700: #008a00;
$spruce-500: #00ad24;

// Lighter
$spruce-400: #00b800;
$spruce-300: #57d374;
$spruce-200: #bdecc4;
$spruce-100: #e3f7e7;

// Lighter
$pear-400: #c3d834;
$pear-200: #dbe885;
$pear-100: #e8f0b0;

/////////////
// ORANGES //
/////////////

// Darker
$chestnut-700: #bc644c;

$chestnut-500: #e57758;

// Lighter
$chestnut-400: #e6886e;
$chestnut-300: #e89c87;
$chestnut-200: #edb6a8;
$chestnut-100: #f3d2ca;

// Darker
$golden-sand-700: #e49736;

$golden-sand-500: #e8b43f;

// Lighter
$golden-sand-400: #eabe50;
$golden-sand-300: #eeca67;
$golden-sand-200: #f2d88f;
$golden-sand-100: #f7e7ba;

//////////
// REDS //
//////////

// Darker
$red-700: #981c14;

$red-500: #bd4133; // primary

// Lighter
$red-400: #f5715d;
$red-300: #ffa893;
$red-200: #fcd4ce;
$red-100: #feeeec;

///////////
// Greys //
///////////

// Darker
$grey-700: #182023;
$gray-700: $grey-700;
$grey-600: #424a4c;
$gray-600: $grey-600;
$grey-500: #4b4b4b;
$gray-500: $grey-500; // primary

// Lighter
$grey-400: #6a6a6a;
$gray-400: $grey-400;
$grey-350: #767676;
$gray-350: $grey-350;
$grey-325: #919191;
$gray-325: $grey-325;
$grey-300: #a9a9a9;
$gray-300: $grey-300;
$grey-200: #e9e9e9;
$gray-200: $grey-200;
$grey-150: #faf9f6;
$gray-150: $grey-150;
$grey-100: #f2f2f2;
$gray-100: $grey-100;
$grey-50: #fafafa;
$gray-50: $grey-50;
$grey-25: #fbfbfb;
$gray-25: $grey-25;

$dark: #000;
$light: #fff;

/* Named Colors */

$primary-700: $mineral-green-700;
$primary-600: $mineral-green-600;
$primary-500: $mineral-green-500;
$primary-400: $mineral-green-400;
$primary-300: $mineral-green-300;
$primary-200: $mineral-green-200;
$primary-100: $mineral-green-100;
$primary-50: $mineral-green-50;

$alternate-700: $spruce-700;
$alternate-500: $spruce-500;
$alternate-400: $spruce-400;
$alternate-300: $spruce-300;
$alternate-200: $spruce-200;
$alternate-100: $spruce-100;

$secondary-700: $blue-700;
$secondary-500: $blue-500;
$secondary-400: $blue-400;
$secondary-300: $blue-300;
$secondary-200: $blue-200;
$secondary-100: $blue-100;

$tertiary-700: $chestnut-700;
$tertiary-500: $chestnut-500;
$tertiary-400: $chestnut-400;
$tertiary-300: $chestnut-300;
$tertiary-200: $chestnut-200;
$tertiary-100: $chestnut-100;

$accent-700: $golden-sand-700;
$accent-500: $golden-sand-500;
$accent-400: $golden-sand-400;
$accent-300: $golden-sand-300;
$accent-200: $golden-sand-200;
$accent-100: $golden-sand-100;

$black: $dark;
$white: $light;

$info-700: $blue-700;
$info-500: $blue-500;
$info-400: $blue-400;
$info-300: $blue-300;
$info-200: $blue-200;
$info-100: $blue-100;

$success-700: $spruce-700;
$success-500: $spruce-500;
$success-400: $spruce-400;
$success-300: $spruce-300;
$success-200: $spruce-200;
$success-100: $spruce-100;

$warn-700: $golden-sand-700;
$warn-500: $golden-sand-500;
$warn-400: $golden-sand-400;
$warn-300: $golden-sand-300;
$warn-200: $golden-sand-200;
$warn-100: $golden-sand-100;

$error-700: $red-700;
$error-500: $red-500;
$error-400: $red-400;
$error-300: $red-300;
$error-200: $red-200;
$error-100: $red-100;

$link: $primary-500;
$link-hover: $primary-600;

$form-700: $mineral-green-700;
$form-500: $mineral-green-500;
$form-400: $mineral-green-400;
$form-300: $mineral-green-300;
$form-200: $mineral-green-200;
$form-100: $mineral-green-100;

$form-value: $grey-500;
$form-label: $grey-400;
$form-placeholder: $grey-300;
$form-disabled: $grey-200;
$form-border: $grey-325;
$form-border-active: $form-500;

///////////////////
//  Breakpoints  //
///////////////////

$xs: 0px;
$xxsm: 200px;
$xsm: 250px;
$sm: 540px;
$md: 720px;
$lg: 960px;
$xlg: 1140px;

///////////////////
//  Border Radius  //
///////////////////

$sm-radius: 4px;
$md-radius: 8px;
$lg-radius: 16px;
$xlg-radius: 32px;
$circle-radius: 50%;

///////////////////
//     Misc      //
///////////////////

$admin-nav-height: 64px;
$landing-nav-height: 64px;
$memberDash-nav-height: 72px;
$memberDash-sidebar-width-open: 290px;
$memberDash-sidebar-width-closed: 84px;
$memberDash-sidebar-padding: 32px;

$gutter: 16px;
$loader-height: 8px;

$pi: 3.14159265358979;

:export {
  GREY_700: $grey-700;
  GREY_600: $grey-600;
  GREY_500: $grey-500;
  GREY_400: $grey-400;
  GREY_300: $grey-300;
  GREY_200: $grey-200;
  GREY_150: $grey-150;
  GREY_100: $grey-100;
  GREY_50: $grey-50;

  GRAY_700: $gray-700;
  GRAY_500: $gray-500;
  GRAY_400: $gray-400;
  GRAY_300: $gray-300;
  GRAY_200: $gray-200;
  GRAY_150: $gray-150;
  GRAY_100: $gray-100;
  GRAY_50: $gray-50;

  DARK: $dark;
  LIGHT: $light;

  PRIMARY_700: $primary-700;
  PRIMARY_600: $primary-600;
  PRIMARY_500: $primary-500;
  PRIMARY_400: $primary-400;
  PRIMARY_300: $primary-300;
  PRIMARY_200: $primary-200;
  PRIMARY_100: $primary-100;
  PRIMARY_50: $primary-50;

  ALTERNATE_700: $alternate-700;
  ALTERNATE_500: $alternate-500;
  ALTERNATE_400: $alternate-400;
  ALTERNATE_300: $alternate-300;
  ALTERNATE_200: $alternate-200;
  ALTERNATE_100: $alternate-100;

  SECONDARY_700: $secondary-700;
  SECONDARY_500: $secondary-500;
  SECONDARY_400: $secondary-400;
  SECONDARY_300: $secondary-300;
  SECONDARY_200: $secondary-200;
  SECONDARY_100: $secondary-100;

  TERTIARY_700: $tertiary-700;
  TERTIARY_500: $tertiary-500;
  TERTIARY_400: $tertiary-400;
  TERTIARY_300: $tertiary-300;
  TERTIARY_200: $tertiary-200;
  TERTIARY_100: $tertiary-100;

  ACCENT_700: $accent-700;
  ACCENT_500: $accent-500;
  ACCENT_400: $accent-400;
  ACCENT_300: $accent-300;
  ACCENT_200: $accent-200;
  ACCENT_100: $accent-100;

  FORM_700: $form-700;
  FORM_500: $form-500;
  FORM_400: $form-400;
  FORM_300: $form-300;
  FORM_200: $form-200;
  FORM_100: $form-100;
}
