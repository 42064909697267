@import 'variables';

//////////////
// Shadows //
//////////////

// https://css-tricks.com/snippets/sass/material-shadows-mixin/

/**
 * Computes a top-shadow for a card effect.
 *
 * @param {Number} $depth - depth level
 *
 * @return {List}
 */
@function top-shadow($depth, $shadowColor: black) {
  $primary-offset: nth(1.5 3 10 14 19, $depth) * 1px;
  $blur: nth(1.5 3 10 14 19, $depth) * 4px;
  $color: rgba($shadowColor, nth(0.1 0.12 0.16 0.18 0.24, $depth));

  @return 0 $primary-offset $blur $color;
}

/**
 * Computes a bottom-shadow for a card effect.
 *
 * @param {Number} $depth - depth level
 *
 * @return {List}
 */
@function bottom-shadow($depth, $shadowColor: black) {
  $primary-offset: nth(3 3 6 10 15, $depth) * 1px;
  $blur: nth(3 3 3 5 6, $depth) * 4px;
  $color: rgba($shadowColor, nth(0.12 0.15 0.15 0.16 0.16, $depth));

  @return 0 $primary-offset $blur $color;
}

/**
 * Gives a card depth effect.
 *
 * @param {Number} $depth - depth level (between 1 and 5)
 *
 * @link https://www.google.com/design/spec/layout/layout-principles.html#layout-principles-dimensionality Google Design
 *
 * @requires {function} top-shadow
 * @requires {function} bottom-shadow
 */
@mixin depth($depth, $color: $black) {
  @if $depth < 1 {
    box-shadow: none;
  } @else if $depth > 5 {
    @warn "Invalid $depth `#{$depth}` for mixin `depth`.";
  } @else {
    box-shadow: bottom-shadow($depth, $color), top-shadow($depth, $color);
  }
}

@mixin insetShadow($color: $gray-200, $top: 1px, $left: 2px) {
  box-shadow: inset 1.5px $top rgba($color, 0.24), inset 1.5px $left rgba($color, 0.12);
}

////////////////////////////
// Layout and Positioning //
////////////////////////////

@mixin coverScreen() {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 999; // google's autocomplete typeahead has z-index of 1000
}

@mixin absoluteCenter() {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

@mixin container() {
  position: relative;
  padding: 0 16px;
  width: 100%;
  margin: 0 auto;
}

/////////////
// Styling //
/////////////

@mixin linkStyles() {
  color: $link;
  text-decoration: none;
  cursor: pointer;

  transition: all 0.1s ease-in-out;

  &:hover {
    color: $link-hover;
  }

  &.disabled {
    color: $gray-300;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.bold {
    font-weight: bold;
  }

  &.small {
    font-size: 14px;
  }

  &.letterSpacing {
    letter-spacing: 0.8px;
  }

  &.inheritColor {
    color: inherit !important;
  }
}
