@import './animations';
@import './mixins';
@import './variables';

// FROM 9/14/17
// This is an ugly hack to include the animation keyframes from smeargle
// For whatever reason, the individual names need the :global designation
// Hopefully, css-loader (I think) will be updated to fix this
//
// -Kyle

@keyframes :global(styles__grow-shrink-animation___3HeEN) {
  0% {
    stroke-dashoffset: 186.92476;
  }
  50% {
    stroke-dashoffset: 21.99115;
  }
  100% {
    stroke-dashoffset: 186.92476;
  }
}

@keyframes :global(styles__rotate-animation___1qy_D) {
  from {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  to {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

// END HACK

:global {
  @import 'src/@spring/smeargle/styles/root.scss';

  body.protect-overflow {
    min-height: 100vw;
    height: 100%;
    width: 100vw;
    overflow-x: hidden;
  }

  a {
    @include linkStyles();
  }
}

@font-face {
  font-family: 'Hanken Grotesk';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('/fonts/hanken-grotesk-latin-400-normal.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Hanken Grotesk';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('/fonts/hanken-grotesk-latin-500-normal.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Hanken Grotesk';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url('/fonts/hanken-grotesk-latin-600-normal.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Hanken Grotesk';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('/fonts/hanken-grotesk-latin-700-normal.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  text-rendering: optimizeLegibility;
  font-feature-settings: 'ss03' on;

  // Not autoprefixerable
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

p {
  margin: 0;
}
