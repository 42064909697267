@import 'animations';
@import 'mixins';
@import 'variables';

* {
  box-sizing: border-box;

  &::selection {
    background-color: $tertiary-200;
  }
}

body {
  padding: 0;
  margin: 0;
  text-rendering: optimizeLegibility;
  font-feature-settings: 'ss03' on;

  // Not autoprefixerable
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
