////////////
// Rotate //
////////////

@keyframes rotate-animation {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

@mixin rotate($duration: 2s) {
  animation: rotate-animation linear $duration infinite;
}

///////////
// Blink //
///////////

@keyframes blink-animation {
  from,
  to {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }
}

@mixin blink($duration: 1s) {
  animation: blink-animation linear $duration infinite;
}
